.templateTabPanel {
    margin-top: 0;
    padding: 0 !important;
    width: 100%;
    min-height: 400px;
    overflow: auto;
}

.templateTabPanelContent {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}
