.dsg-input {
    display: block;
    flex: 1;
    border: none;
    min-width: 0;
    align-self: stretch;
    box-sizing: border-box;
    background: none;
    padding: 0 8px;
    font-size: .75rem;
    outline: none;
}

.dsg-container{
    height: 600px;
}

.rdg-row{
    font-size: .8rem;
    text-align: left;
}

.rdg-cell{
    border-width: 1px;
    padding: 3px;
    border-color: #eee;
    outline-width: 1px;
}

.rdg-row-odd {
    background-color: #f8f9fa;
}

.idColumn {
    background-color: #ddd;
    width: 40px;
    font-size: .55rem;
    text-align: center;
}

.newRecordClass {

}
.newRecordClass .rdg-cell {
   background-color: #f0f5fb;
}

.newRecordClass .requiredCell {
    background-color: #fff6f6;
}

.newRecordClass .requiredCell .rdg-cell {
    background-color: #f0f5fb;
}

.requiredCell {
    background-color: #fff6f6;
}

.requiredCell .rdg-cell {
    background-color: #f0f5fb;
}


.newRecordClass .standardCell {
    background-color: inherit !important;
}

.newRecordClass .standardCell .rdg-cell {
    background-color: inherit !important;
}

.standardCell {
    background-color: inherit !important;
}

.standardCell .rdg-cell {
    background-color: inherit !important;
}



.baseEditInput {
    padding: 11px 2px 6px;
    outline: 1px;
    border: 1px #ccc solid;
}
.baseEditInputAdd {
    width: 100%;
    margin-top: -5px;
    border-radius: 0;
    padding: 0;
}

.baseEditInput .baseEditInputAdd {
    width: 100%;
    margin-top: -5px;
    border-radius: 0;
    padding: 0;
}

.columnRenderImageContent {
    padding: 2px;
    background-color: #fff;
    border: 1px solid #eee;
    height: 20px;
    border-radius: 2px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
    line-height: 28px;
    vertical-align: middle;
}

