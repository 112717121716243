
.childList {
    width: 100%;
    margin-left: 5px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.childListItem {
    padding-left: 5px !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin-left: 5px !important;
    margin-bottom: 0 !important;
    cursor: context-menu;
    display: flex;
    background-color: #445566;
    flex-direction: row;
    color: #eee;
}

.childListItemIcon {

    display: inline-block;
    padding-left: 0 !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
    color: #eee;
    min-width: 20px !important;
}

.childListItemButton {
    min-height: 24px !important;
    padding: 3px 5px 3px 0 !important;
    display: inline-flex;
    flex-grow: 0;
    color: #eee;
    max-width: 20px;
}

.childListItemText {
    font-size: .7rem;
    display: inline-block;
    padding-left: 5px;
    color: #eee
}

