
.home {
    height: 100%;
    width: 100%;
}

.homeLogo {
    width: 95%;
    max-width: 500px;
}

.homeLogoContainer {
    margin-top: 40px;
    width: 100%;
}

.homeContentContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 10px;
}
