
.uploadContainer {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 10px;
}

.columnTypeButton {
    min-width: 60px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 10px !important;
}


