.easy-edit-wrapper {
    display: inline-block;
}

.easy-edit-inline-wrapper{
    display: inline-block;
 }

.easy-edit-component-wrapper{
    display: inline-block;
}

.easy-edit-component-wrapper input{
    height: 21px;
    border-radius: 2px;
    border: 1px solid #959595;
    padding: 3px;
    outline: none;
}

.easy-edit-button{
    background: transparent;
    margin: 0 2px !important;
    padding: 0 1px !important;
}

.MuiInput-root .MuiInput-input {
    font-size: .8rem;
    line-height: 1em;
}

.query-builder {
    margin-top: 5px !important;
}