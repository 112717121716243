
.leftNavAppBarBase {
    position: relative !important;
    text-align: left;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: .9rem;
    font-weight: bold;
}

.leftNavListItemButton {
    min-height: 24px !important;
    padding: 3px 5px 3px 0 !important;
    display: inline-flex;
    flex-grow: 0;
    color: #eee;
    max-width: 20px;
}
